import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-426086c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fieldCfg" }
const _hoisted_2 = { style: {"padding":"10px 30px 10px 10px"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_Unit = _resolveComponent("Unit")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.otherParams.visible,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.otherParams.visible) = $event)),
      "append-to-body": "true",
      draggable: "",
      width: "50%",
      center: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "iconfont icon-shanchu",
          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.otherParams.visible=false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          class: "iconfont icon-queding",
          onClick: _ctx.fieldInfoSure
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form, {
            ref: el=>_ctx.refMap.set('formRef',el),
            model: _ctx.fieldItem,
            rules: _ctx.type==0?_ctx.otherParams.rules1:_ctx.otherParams.rules2,
            "label-width": "100px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 11 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "字段名称",
                        prop: "label"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            onInput: _cache[0] || (_cache[0] = e => _ctx.fieldItem.label = _ctx.valid(e)),
                            modelValue: _ctx.fieldItem.label,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fieldItem.label) = $event)),
                            clearable: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 11,
                    offset: 2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "字段类型",
                        prop: "type"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: _ctx.fieldItem.type,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fieldItem.type) = $event)),
                            style: {"width":"100%"},
                            disabled: _ctx.cfgName=='listGrid',
                            onChange: _cache[3] || (_cache[3] = (newVal)=>{_ctx.selectChange(newVal,'type')})
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherParams.componentType, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  label: item,
                                  value: item
                                }, null, 8, ["label", "value"]))
                              }), 256))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.fieldItem.type=='comboGrid' || _ctx.fieldItem.type=='comboTree')
                ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "请求路径",
                            prop: "modelMethod"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                onInput: _cache[4] || (_cache[4] = e => _ctx.fieldItem.modelMethod = _ctx.valid(e)),
                                modelValue: _ctx.fieldItem.modelMethod,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.fieldItem.modelMethod) = $event)),
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.type==0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        (_ctx.fieldItem.type=='input')
                          ? (_openBlock(), _createBlock(_component_el_col, {
                              key: 0,
                              span: 11
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_form_item, {
                                  label: "字段长度",
                                  prop: "maxlength"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Unit, {
                                      modelValue: _ctx.fieldItem.maxlength,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fieldItem.maxlength) = $event)),
                                      unit: "位",
                                      min: 0,
                                      max: 1000
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_el_col, {
                          span: 11,
                          offset: 2
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "是否必填",
                              prop: "required"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_switch, {
                                  modelValue: _ctx.fieldItem.required,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fieldItem.required) = $event)),
                                  "inline-prompt": "",
                                  "active-text": "是",
                                  "inactive-text": "否"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 11 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "是否禁用",
                              prop: "disabled"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.fieldItem.disabled,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.fieldItem.disabled) = $event)),
                                  style: {"width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "否",
                                      value: false
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "是",
                                      value: true
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, {
                          span: 11,
                          offset: 2
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "是否可清除",
                              prop: "clearable"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.fieldItem.clearable,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.fieldItem.clearable) = $event)),
                                  style: {"width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "否",
                                      value: false
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "是",
                                      value: true
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 11 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "标题对其方式",
                              prop: "headerAlign"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.fieldItem.headerAlign,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.fieldItem.headerAlign) = $event)),
                                  style: {"width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "left",
                                      value: "left"
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "center",
                                      value: "center"
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "right",
                                      value: "right"
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, {
                          span: 11,
                          offset: 2
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "内容对其方式",
                              prop: "align"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.fieldItem.align,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.fieldItem.align) = $event)),
                                  style: {"width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "left",
                                      value: "left"
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "center",
                                      value: "center"
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "right",
                                      value: "right"
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 11 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "字段宽度",
                              prop: "width"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Unit, {
                                  modelValue: _ctx.fieldItem.width,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.fieldItem.width) = $event)),
                                  unit: "位",
                                  min: 0,
                                  max: 1000
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, {
                          span: 11,
                          offset: 2
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "排序方式",
                              prop: "sortable"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.fieldItem.sortable,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.fieldItem.sortable) = $event)),
                                  clearable: "",
                                  style: {"width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "前端排序",
                                      value: "true"
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "后端排序",
                                      value: "custom"
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}